<template>
  <div class="home">
    <div class="info">
      <div class="newsSort">
        <span>最近1日新增資料</span>
        <div id="chart" v-if="dataBar[0].data.length>1">
          <strong style="font-size: 18px; color: red; margin-left: 5rem"></strong>
          <apexcharts type="bar" height="350" :options="chartOptions" :series="dataBar" ref="demoChart"></apexcharts>
        </div>
      </div>
      <div class="caseStatus">
        <div class="caseStatus--status">
          <div><span>最近1週名單統計</span></div>
          <section>
            <article>
              <p class="blue">{{caseStatistics.edit}}</p>
              <a class="blueText" @click="routerPushCaseManage('edit')">編輯中</a>
            </article>
            <article>
              <p class="gray">{{caseStatistics.waitApproval}}</p>
              <a class="grayText" @click="routerPushCaseManage('wait_approval')">待覆核</a>
            </article>
            <article>
              <p class="red">{{caseStatistics.approval}}</p>
              <a class="redText" @click="routerPushCaseManage('approval')">已覆核</a>
            </article>
            <article>
              <p class="orange">{{caseStatistics.reject}}</p>
              <a class="orangeText" @click="routerPushCaseManage('reject')">已退回</a>
            </article>
          </section>
        </div>

        <section class="caseStatus--list">
          <div><span>已覆核警示名單</span></div>
          <article>
            <div v-if="approvalListData.length>0">
              <a v-for="item in approvalListData" :key="item.ListID"
                @click="previewWarningForm(item)"
              >{{item.Name}}
                <span>{{','}}</span>
              </a>
              
            </div>
            <div class="textWrap" v-else>
              <span class="text">近一週無已覆核警示名單</span>
            </div>
          </article>
        </section>
      </div>
    </div>
    <div class="listWrap">
      <div class="listWrap__button" v-if="isOperator">
        <el-button type="primary" @click="toggleDialog">
          <font-awesome-icon icon="plus" style="margin-right: 5px"/>建立警示名單
        </el-button>
        <el-button type="primary" @click="editRelatedNews">
          <font-awesome-icon icon="plus" style="margin-right: 5px"/>加入關聯資訊
        </el-button>
      </div>
      <div class="listWrap__content">
        <!-- *負面新聞資料* -->
        <el-table v-if="typeNews=='searchNews'" v-loading="loading" style="width: 100%" ref="multipleTable" max-height="400"
          :data="tableData"
          @select="getSelectedRow"
        >
          <el-table-column type="selection" width="60" v-if="isOperator"></el-table-column>
          <el-table-column prop="order" label="序號" width="60"></el-table-column>
          <el-table-column prop="NewsTitle" label="新聞標題" width="400">
            <template slot-scope="scope">
              <a class="goDetailPage" @click="previewDetailPage(scope.row,'detailNews')">{{scope.row.NewsTitle}}</a>
            </template>
          </el-table-column>
          <el-table-column prop="NewsTime" label="新聞時間" width="250">
            <template slot-scope="scope">
              <div>{{ filterDate(scope.row.NewsTime) }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="NewsSite" label="新聞網站" width="150"></el-table-column>
          <el-table-column prop="NewsChannel" label="新聞頻道" width="150"></el-table-column>
          <el-table-column prop="Sentiment" label="情緒指標">
            <template slot-scope="scope">
              {{ transSentimentName(scope.row.Sentiment) }}
            </template>
          </el-table-column>
        </el-table>

        <!-- *聯合國制裁名單* -->
        <el-table v-if="typeNews=='searChsan'" v-loading="loading" style="width: 100%" ref="multipleTable" max-height="400"
          :data="tableData"
          @select="getSelectedRow"
        >
          <el-table-column type="selection" width="60" v-if="isOperator"></el-table-column>
          <el-table-column prop="SourceName" label="來源名稱" width="300">
            <template slot-scope="scope">
              <a class="goDetailPage" @click="previewDetailPage(scope.row,'tableDetailNews','searChsan')">{{scope.row.SourceName}}</a>
            </template>
          </el-table-column>
          <el-table-column prop="ListType" label="名單分類" width="150"></el-table-column>
          <el-table-column prop="ListID" label="名單編號" width="150"></el-table-column>
          <el-table-column prop="UNListType" label="聯合國名單種類" width="150"></el-table-column>
          <el-table-column prop="PublicDt" label="發布時間"></el-table-column>
        </el-table>

        <!-- *政府拒絕往來廠商名單* -->
        <el-table v-if="typeNews == 'searchConstr'" v-loading="loading" style="width: 100%" ref="multipleTable" max-height="400"
          :data="tableData"
          @select="getSelectedRow"
        >
          <el-table-column type="selection" width="60" fixed v-if="isOperator"></el-table-column>
          <el-table-column prop="CaseName" label="標案名稱" width="150">
             <template slot-scope="scope">
              <a class="goDetailPage" @click="previewDetailPage(scope.row,'tableDetailNews','searchConstr')">{{scope.row.CaseName}}</a>
            </template>
          </el-table-column>
          <el-table-column prop="UniComNm" label="廠商代碼" width="100"></el-table-column>
          <el-table-column prop="ComName" label="廠商名稱" width="150"></el-table-column>
          <el-table-column prop="GPActStatus" label="符合政府採購法第101條所列之情形" width="400"></el-table-column>
          <el-table-column prop="PublicDt" label="公告日期" width="120"></el-table-column>
          <el-table-column prop="EffectDt" label="拒絕往來生效日"></el-table-column>
        </el-table>
        <!-- *違反勞動法名單* -->
        <el-table v-if="typeNews == 'searchLabor'" v-loading="loading" style="width:100%" ref="multipleTable" max-height="400"
          :data="tableData"
          @select="getSelectedRow"
        >
          <el-table-column type="selection" width="60" fixed v-if="isOperator"></el-table-column>
          <el-table-column prop="UnitEntity" label="事業單位名稱" width="180">
            <template slot-scope="scope">
              <a class="goDetailPage" @click="previewDetailPage(scope.row,'tableDetailNews','searchLabor')">{{scope.row.UnitEntity}}</a>
            </template>
          </el-table-column>
          <el-table-column prop="PublicDt" label="公告日期" width="150"></el-table-column>
          <el-table-column prop="PunishDt" label="處分日期" width="150"></el-table-column>
          <el-table-column prop="VioLaw" label="違法法規法條"></el-table-column>
        </el-table>
        <!-- *環保署裁罰處分名單* -->
        <el-table v-if="typeNews == 'searchEnviroment'" v-loading="loading" style="width:100%" ref="multipleTable" max-height="400"
          :data="tableData"
          @select="getSelectedRow"
        >
          <el-table-column type="selection" width="60" fixed v-if="isOperator"></el-table-column>
          <el-table-column prop="CaseName" label="案件名稱" width="250">
            <template slot-scope="scope">
              <a class="goDetailPage" @click="previewDetailPage(scope.row,'tableDetailNews','searchEnviroment')">{{scope.row.CaseName}}</a>
            </template>
          </el-table-column>
          <el-table-column prop="UnitName" label="行為人名稱" width="120"></el-table-column>
          <el-table-column prop="PunishTime" label="違反處分時間" width="120"></el-table-column>
          <el-table-column prop="VioLaw" label="違反法令"></el-table-column>
        </el-table>
      </div>
      <Pagination
        @currentChange="pageChange"
        :pageSize="this.listQuery.Page_Size"
        :propsCurrentPage="this.listQuery.Page_Num"
        :totalLists="totalLists"
      ></Pagination>
    </div>
    <div v-if="openPopUp" class="WarningListPopUp">
      <div class="popup">
        <i class="el-icon-close" @click="toggleDialog"></i>
        <p>請選擇要新增的名單類別</p>
        <div>
          <el-button type="danger" @click="addWarningForm('people','add')">人名</el-button>
          <el-button type="danger" @click="addWarningForm('org','add')">組織</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import Pagination from "../components/Pagination.vue";
export default {
  components: {apexcharts: VueApexCharts,Pagination,},
  data() {
    return {
      typeNews: "searchNews",
      openPopUp: false,
      loading:true,
      tableData: [],
      totalLists: null,
      approvalListData:[],
      caseStatistics:{
        edit:"",
        waitApproval:"",
        approval:"",
        reject:""
      },
      listQuery: {
        Page_Size: 10, //每頁有幾筆
        Page_Num: 1, //目前頁數
        // Start_Date: this.$dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
        // End_Date: this.$dayjs().format('YYYY-MM-DD'),
      },
      manageAllSelection:{
        searchNews:[],
        searChsan:[],
        searchConstr:[],
        searchLabor:[],
        searchEnviroment:[]
      },
      dataBar: [
        {
          name: "筆數",
          data: [],
        },
      ],
    };
  },
  computed: {
    isOperator(){
      let result = null;
      if(this.$store.state.userInfo.Role==='operator'){
        result = true
      }else{
        result = false
      }
      return result
    },
    transSentimentName(){
      return (En) => {
        let transName = "無";
        if(En){
          switch(En){
            case 'positive':
              transName = '正向'
              break
            case 'negative':
              transName = '負向'
              break
            case 'neutral':
              transName = '中立'
              break
          }
        }
        return transName;
      };
    },
    filterDate() {
      return (date) => {
        return this.$dayjs(date).format('YYYY-MM-DD HH:mm:ss');
      };
    },
    chartOptions: function () {
      return {
        chart: {
          toolbar: {
            show: false,
          },
          type: "bar",
          width: 200,
          // height: 150,
          events: {
            dataPointMouseEnter: (event) => {
              event.path[0].style.cursor = "pointer";
              // console.log(event);
            },
            dataPointSelection: function(event, chartContext, config) {
                    console.log('seriesIndex', config.seriesIndex);
                    console.log('dataPointIndex', config.dataPointIndex);
                     console.log(config);

            },
            click: (event, chartContext, config) => {
              console.log(config,event,chartContext)
              let NewsName =
                config.config.xaxis.categories[config.dataPointIndex];
              switch (NewsName) {
                case "負面新聞資料":
                  this.typeNews = "searchNews";
                  break;
                case "聯合國制裁名單":
                  this.typeNews = "searChsan";
                  break;
                case "政府拒絕往來廠商名單":
                  this.typeNews = "searchConstr";
                  break;
                case "違反勞動法名單":
                  this.typeNews = "searchLabor";
                  break;
                case "環保署裁罰處分名單":
                  this.typeNews = "searchEnviroment";
                  break;
              }
              this.listQuery.Page_Num = 1;
              this.getList(this.typeNews,true);
            },
          },
          animations:{
            enabled: false,
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            barHeight: "60%",
            columnWidth: '10%',
          },
        },
        dataLabels: {
          enabled: true,
          
          style: {
            fontSize: "16px",
            colors: ["#111", "#555", "#999"],
          },
        },
        xaxis: {
          categories: [
            "負面新聞資料",
            "聯合國制裁名單",
            "政府拒絕往來廠商名單",
            "違反勞動法名單",
            "環保署裁罰處分名單",
          ],
          labels: {
            show: false,
          },
          title: {
            style: {
              fontSize: "1px",
            },
          },
        },
        colors: ["rgb(245, 232, 152)"],
        yaxis: {
          showAlways: false,
          // showForNullSeries:true,
          labels: {
            cursor: 'pointer',
            style: {
              fontSize: "16px",
              cursor:"pointer",
               cssClass:"yaxisTest"
            },
          },
        },
        tooltip: {
          followCursor:true,
          enabled: true,
          style: {
            fontSize: 10,
            fontFamily: 0,
          },
        },
        markers:{
          size: 0,
        },
        // dataLabels:{
        //   enabled: false,
        // }
      };
    },
  },
  methods: {
    pageChange(val) {
      this.listQuery.Page_Num = val;
      this.getList(this.typeNews);
    },
    routerPushCaseManage(status){
      this.$router.push({
        name: "caseManage",
        query: {
          caseStatus:status,
          startDate:this.$dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
          endDate:this.$dayjs().format('YYYY-MM-DD'),
        }
      });
    },
    previewWarningForm(item){
      this.$router.push({
        name: "warningListAdd",
        query: {
          caseStatus:item.Status,
          permission:item.Permission,
          warningFormType:item.ListType,
          listID:item.ListID,
        }
      });
    },
    addWarningForm(formType,actionsType) {
      this.$router.push({
        name: "warningListAdd",
        query: {
          caseStatus:actionsType,
          warningFormType: formType,//people或 org
          typeNews: this.typeNews,
          DataID:JSON.stringify(this.manageAllSelection),
        }
      });
    },
    editRelatedNews() {
      this.$router.push({
        name: "relatedNews",
        query: {
          caseStatus:'',
          warningFormType: 'people',//people或 org
          typeNews: this.typeNews,
          DataID:JSON.stringify(this.manageAllSelection),
        }
      });
    },
    previewDetailPage(item,typeDetail,typeNews){
      const route = this.$router.resolve({
        name:typeDetail,
        query: {
          DataID:item.DataID,
          typeNews:typeNews
        }
      })
      window.open(route.href,'_blank')
    },
    getRowKeys(row){
       return row.DataID;
    },
    /*手動勾選數據Checkbox所觸發的event*/
    getSelectedRow(selection,row){
      let manageAllDataID = this.manageAllSelection[this.typeNews].map((item)=>item.DataID);
      let isIncludes = manageAllDataID.includes(row.DataID);
      if(isIncludes){
        // delete
        let index = manageAllDataID.indexOf(row.DataID)
        this.manageAllSelection[this.typeNews].splice(index,1)
      }else{
        //push
        this.manageAllSelection[this.typeNews].push(row)
      }
    },
    makeTableShowChecked(){
        this.$nextTick(()=>{
          this.tableData.forEach((item) => {
            this.manageAllSelection[this.typeNews].forEach((selectedItem) => {
              if (item.DataID === selectedItem.DataID) {
                this.$refs.multipleTable.toggleRowSelection(item, true);
              }
            });
          });
        })
    },
    toggleDialog() {
      this.openPopUp = !this.openPopUp;
    },
    //取得案件狀態
    getCaseStatus(){
      let requestData = {
        CreateUserID:this.$store.state.userInfo.UserID,
        Start_Date:this.$dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
        End_Date:this.$dayjs().format('YYYY-MM-DD'),
      }
      this.$api.list.statistics(requestData).then((res)=>{
        let {edit,wait_approval,approval,reject} = {...res}
        this.caseStatistics.edit = edit.count
        this.caseStatistics.waitApproval = wait_approval.count
        this.caseStatistics.approval = approval.count
        this.caseStatistics.reject = reject.count
        let approvalListID = approval.data.map((item)=>(item.ListID))
        if(approvalListID.length > 0){
          let temp = {
            UserID: this.$store.state.userInfo.UserID,
            ListID: approvalListID,
            Page_Size: 9999, //每頁有幾筆
            Page_Num: 1, //目前頁數
          }
          this.$api.list.searchList(temp).then((res) => {
            this.approvalListData = res.data
          });
        }

      })
    },
    getList(typeNews,IsUser) {
      this.loading = true;
      if(!!IsUser){
        this.listQuery.IsUser = true
      }
      this.$api.data[typeNews](this.listQuery).then((res) => {
        this.tableData = [];
        this.totalLists = res.total;
        if(typeNews==='searChsan'){
          this.tableData = res.data.map((item)=>item.SanList)
        }else if(typeNews==='searchNews'){
          let order = 1;
          res.data.forEach((item) => {
            item.order = order;
            order++;
          });
          this.tableData = res.data;
        }else{
          this.tableData = res.data;
        }
        this.loading = false;
        this.makeTableShowChecked()
      });
    },
    searchNews(){
      this.$store.dispatch("loadingHandler", true);
      return new Promise((resolve)=>{
        this.$api.data.searchNews(this.listQuery).then((res)=>{
          let order = 1;
          res.data.forEach((item) => {
            item.order = order;
            order++;
          });
          this.tableData = res.data;
          this.totalLists = res.total;
          this.loading = false
          resolve(res)
        })
      })
    },
    searChsan(){
      return new Promise((resolve)=>{
        this.$api.data.searChsan(this.listQuery).then((res)=>{
          resolve(res)
        })
      })
    },
    searchConstr(){
      return new Promise((resolve)=>{
        this.$api.data.searchConstr(this.listQuery).then((res)=>{
          resolve(res)
        })
      })
    },
    searchLabor(){
      return new Promise((resolve)=>{
        this.$api.data.searchLabor(this.listQuery).then((res)=>{
          resolve(res)
        })
      })
    },
    searchEnviroment(){
      return new Promise((resolve)=>{
        this.$api.data.searchEnviroment(this.listQuery).then((res)=>{
          resolve(res)
        })
      })
    },
  },
  async mounted() {
    Promise.all([
      this.searchNews(),
      this.searChsan(),
      this.searchConstr(),
      this.searchLabor(),
      this.searchEnviroment(),
      this.getCaseStatus()
    ]).then((res)=>{
      
      this.dataBar[0].data[0] = res[0].total;
      this.dataBar[0].data[1] = res[1].total;
      this.dataBar[0].data[2] = res[2].total;
      this.dataBar[0].data[3] = res[3].total;
      this.dataBar[0].data[4] = res[4].total;
      // this.chartOptions.plotOptions.bar.columnWidth = '70%'
      
      this.$store.dispatch("loadingHandler", false);
    })
  },
};
</script>
<style lang="scss" scoped>
.home {
  height:100vh;
  background: linear-gradient(-135deg, #00abb9 20%, #fff 50%, transparent 50%)
    center center / 100% 100%;
  .info {
    display: flex;
    justify-content: space-around;
    padding-top: 1%;
    .newsSort {
      width: 58%;
    }
    .caseStatus {
      width: 38%;
      &--status {
        margin-bottom: 2%;
        section {
          background-color: white;
          height: auto;
          display: flex;
          justify-content: space-around;
          margin: auto;
          border-radius: 0px 0px 5px 5px;
          padding: 1% 1%;
          article {
            text-align: center;
            p {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 80px;
              height: 80px;
              font-size: 2rem;
              border-radius: 999rem;
              margin-bottom: 20%;
            }
            span {
              margin: auto;
            }
            //文字顏色
            .blue {
              background-color: rgb(90, 149, 203);
              color: white;
            }
            .blueText {
              color: rgb(90, 149, 203);
            }
            .gray {
              background-color: gray;
              color: white;
            }
            .grayText {
              color: gray;
            }
            .red {
              background-color: red;
              color: white;
            }
            .redText {
              color: red;
            }
            .orange {
              background-color: orange;
              color: white;
            }
            .orangeText {
              color: orange;
            }
          }
        }
      }
      &--status > div {
        display: block;
        width: 100%;
        border-radius: 5px 5px 0px 0px;
        background-color: #e0ffff;
        span {
          display: block;
          padding: 2% 2%;
        }
      }

      &--list {
        article {
          background-color: white;
          height: auto;
          justify-content: space-around;
          margin: auto;
          border-radius: 0px 0px 5px 5px;
          padding: 2% 2%;
          height: 100px;
          overflow-y:scroll;
          > div{
            display: flex;
            flex-wrap: wrap;
          }
          a{
            margin-right: 10px;
            margin-bottom: 6px;
            cursor: pointer;
            &:hover{
              color: cadetblue;
            }
          }
          .textWrap{
            display: flex;
            align-items: center;
            justify-content: center;
            height: inherit;
            .text{
              color:gray
            }
          }
        }
      }
      &--list > div {
        display: block;
        width: 100%;
        border-radius: 5px 5px 0px 0px;
        background-color: #e0ffff;
        span {
          display: block;
          padding: 2% 2%;
        }
      }
    }
  }
  .listWrap {
    &__button {
      display: flex;
      justify-content: flex-end;
      margin-bottom:10px;
      padding-right: 1%;
      button {
        margin-left: 10px;
      }
      
    }
    &__content{
      margin-bottom: 20px;
    }
  }
  .WarningListPopUp {
    width: 100%;
    height: 100vh;
    background-color: rgba(133, 133, 133, 0.7);
    position: fixed;
    z-index: 9999999;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .popup {
      position: relative;
      text-align: center;
      width: 20%;
      padding: 5%;
      background-color: white;
      border-radius: 10px;
      .el-icon-close{
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }
  .goDetailPage{
    text-decoration: underline;
    color: blue;
  }
  a{    
    cursor: pointer;
  }
  
}
::v-deep .yaxisTest{
    // font-size: 99px
    cursor: pointer;
  }
</style>
