var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home" }, [
    _c("div", { staticClass: "info" }, [
      _c("div", { staticClass: "newsSort" }, [
        _c("span", [_vm._v("最近1日新增資料")]),
        _vm.dataBar[0].data.length > 1
          ? _c(
              "div",
              { attrs: { id: "chart" } },
              [
                _c("strong", {
                  staticStyle: {
                    "font-size": "18px",
                    color: "red",
                    "margin-left": "5rem"
                  }
                }),
                _c("apexcharts", {
                  ref: "demoChart",
                  attrs: {
                    type: "bar",
                    height: "350",
                    options: _vm.chartOptions,
                    series: _vm.dataBar
                  }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _c("div", { staticClass: "caseStatus" }, [
        _c("div", { staticClass: "caseStatus--status" }, [
          _vm._m(0),
          _c("section", [
            _c("article", [
              _c("p", { staticClass: "blue" }, [
                _vm._v(_vm._s(_vm.caseStatistics.edit))
              ]),
              _c(
                "a",
                {
                  staticClass: "blueText",
                  on: {
                    click: function($event) {
                      return _vm.routerPushCaseManage("edit")
                    }
                  }
                },
                [_vm._v("編輯中")]
              )
            ]),
            _c("article", [
              _c("p", { staticClass: "gray" }, [
                _vm._v(_vm._s(_vm.caseStatistics.waitApproval))
              ]),
              _c(
                "a",
                {
                  staticClass: "grayText",
                  on: {
                    click: function($event) {
                      return _vm.routerPushCaseManage("wait_approval")
                    }
                  }
                },
                [_vm._v("待覆核")]
              )
            ]),
            _c("article", [
              _c("p", { staticClass: "red" }, [
                _vm._v(_vm._s(_vm.caseStatistics.approval))
              ]),
              _c(
                "a",
                {
                  staticClass: "redText",
                  on: {
                    click: function($event) {
                      return _vm.routerPushCaseManage("approval")
                    }
                  }
                },
                [_vm._v("已覆核")]
              )
            ]),
            _c("article", [
              _c("p", { staticClass: "orange" }, [
                _vm._v(_vm._s(_vm.caseStatistics.reject))
              ]),
              _c(
                "a",
                {
                  staticClass: "orangeText",
                  on: {
                    click: function($event) {
                      return _vm.routerPushCaseManage("reject")
                    }
                  }
                },
                [_vm._v("已退回")]
              )
            ])
          ])
        ]),
        _c("section", { staticClass: "caseStatus--list" }, [
          _vm._m(1),
          _c("article", [
            _vm.approvalListData.length > 0
              ? _c(
                  "div",
                  _vm._l(_vm.approvalListData, function(item) {
                    return _c(
                      "a",
                      {
                        key: item.ListID,
                        on: {
                          click: function($event) {
                            return _vm.previewWarningForm(item)
                          }
                        }
                      },
                      [
                        _vm._v(_vm._s(item.Name) + " "),
                        _c("span", [_vm._v(_vm._s(","))])
                      ]
                    )
                  }),
                  0
                )
              : _c("div", { staticClass: "textWrap" }, [
                  _c("span", { staticClass: "text" }, [
                    _vm._v("近一週無已覆核警示名單")
                  ])
                ])
          ])
        ])
      ])
    ]),
    _c(
      "div",
      { staticClass: "listWrap" },
      [
        _vm.isOperator
          ? _c(
              "div",
              { staticClass: "listWrap__button" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.toggleDialog }
                  },
                  [
                    _c("font-awesome-icon", {
                      staticStyle: { "margin-right": "5px" },
                      attrs: { icon: "plus" }
                    }),
                    _vm._v("建立警示名單 ")
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.editRelatedNews }
                  },
                  [
                    _c("font-awesome-icon", {
                      staticStyle: { "margin-right": "5px" },
                      attrs: { icon: "plus" }
                    }),
                    _vm._v("加入關聯資訊 ")
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "listWrap__content" },
          [
            _vm.typeNews == "searchNews"
              ? _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      }
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: { "max-height": "400", data: _vm.tableData },
                    on: { select: _vm.getSelectedRow }
                  },
                  [
                    _vm.isOperator
                      ? _c("el-table-column", {
                          attrs: { type: "selection", width: "60" }
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: { prop: "order", label: "序號", width: "60" }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "NewsTitle",
                        label: "新聞標題",
                        width: "400"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "a",
                                  {
                                    staticClass: "goDetailPage",
                                    on: {
                                      click: function($event) {
                                        return _vm.previewDetailPage(
                                          scope.row,
                                          "detailNews"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(scope.row.NewsTitle))]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1031227391
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "NewsTime",
                        label: "新聞時間",
                        width: "250"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(_vm.filterDate(scope.row.NewsTime))
                                  )
                                ])
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        616771582
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "NewsSite",
                        label: "新聞網站",
                        width: "150"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "NewsChannel",
                        label: "新聞頻道",
                        width: "150"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "Sentiment", label: "情緒指標" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.transSentimentName(
                                        scope.row.Sentiment
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1546290017
                      )
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.typeNews == "searChsan"
              ? _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      }
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: { "max-height": "400", data: _vm.tableData },
                    on: { select: _vm.getSelectedRow }
                  },
                  [
                    _vm.isOperator
                      ? _c("el-table-column", {
                          attrs: { type: "selection", width: "60" }
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: {
                        prop: "SourceName",
                        label: "來源名稱",
                        width: "300"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "a",
                                  {
                                    staticClass: "goDetailPage",
                                    on: {
                                      click: function($event) {
                                        return _vm.previewDetailPage(
                                          scope.row,
                                          "tableDetailNews",
                                          "searChsan"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(scope.row.SourceName))]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2870073002
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "ListType",
                        label: "名單分類",
                        width: "150"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "ListID", label: "名單編號", width: "150" }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "UNListType",
                        label: "聯合國名單種類",
                        width: "150"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "PublicDt", label: "發布時間" }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.typeNews == "searchConstr"
              ? _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      }
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: { "max-height": "400", data: _vm.tableData },
                    on: { select: _vm.getSelectedRow }
                  },
                  [
                    _vm.isOperator
                      ? _c("el-table-column", {
                          attrs: { type: "selection", width: "60", fixed: "" }
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: {
                        prop: "CaseName",
                        label: "標案名稱",
                        width: "150"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "a",
                                  {
                                    staticClass: "goDetailPage",
                                    on: {
                                      click: function($event) {
                                        return _vm.previewDetailPage(
                                          scope.row,
                                          "tableDetailNews",
                                          "searchConstr"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(scope.row.CaseName))]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3023862696
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "UniComNm",
                        label: "廠商代碼",
                        width: "100"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "ComName",
                        label: "廠商名稱",
                        width: "150"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "GPActStatus",
                        label: "符合政府採購法第101條所列之情形",
                        width: "400"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "PublicDt",
                        label: "公告日期",
                        width: "120"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "EffectDt", label: "拒絕往來生效日" }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.typeNews == "searchLabor"
              ? _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      }
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: { "max-height": "400", data: _vm.tableData },
                    on: { select: _vm.getSelectedRow }
                  },
                  [
                    _vm.isOperator
                      ? _c("el-table-column", {
                          attrs: { type: "selection", width: "60", fixed: "" }
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: {
                        prop: "UnitEntity",
                        label: "事業單位名稱",
                        width: "180"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "a",
                                  {
                                    staticClass: "goDetailPage",
                                    on: {
                                      click: function($event) {
                                        return _vm.previewDetailPage(
                                          scope.row,
                                          "tableDetailNews",
                                          "searchLabor"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(scope.row.UnitEntity))]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        738790851
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "PublicDt",
                        label: "公告日期",
                        width: "150"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "PunishDt",
                        label: "處分日期",
                        width: "150"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "VioLaw", label: "違法法規法條" }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.typeNews == "searchEnviroment"
              ? _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      }
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: { "max-height": "400", data: _vm.tableData },
                    on: { select: _vm.getSelectedRow }
                  },
                  [
                    _vm.isOperator
                      ? _c("el-table-column", {
                          attrs: { type: "selection", width: "60", fixed: "" }
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: {
                        prop: "CaseName",
                        label: "案件名稱",
                        width: "250"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "a",
                                  {
                                    staticClass: "goDetailPage",
                                    on: {
                                      click: function($event) {
                                        return _vm.previewDetailPage(
                                          scope.row,
                                          "tableDetailNews",
                                          "searchEnviroment"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(scope.row.CaseName))]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3319124548
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "UnitName",
                        label: "行為人名稱",
                        width: "120"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "PunishTime",
                        label: "違反處分時間",
                        width: "120"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "VioLaw", label: "違反法令" }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _c("Pagination", {
          attrs: {
            pageSize: this.listQuery.Page_Size,
            propsCurrentPage: this.listQuery.Page_Num,
            totalLists: _vm.totalLists
          },
          on: { currentChange: _vm.pageChange }
        })
      ],
      1
    ),
    _vm.openPopUp
      ? _c("div", { staticClass: "WarningListPopUp" }, [
          _c("div", { staticClass: "popup" }, [
            _c("i", {
              staticClass: "el-icon-close",
              on: { click: _vm.toggleDialog }
            }),
            _c("p", [_vm._v("請選擇要新增的名單類別")]),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "danger" },
                    on: {
                      click: function($event) {
                        return _vm.addWarningForm("people", "add")
                      }
                    }
                  },
                  [_vm._v("人名")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "danger" },
                    on: {
                      click: function($event) {
                        return _vm.addWarningForm("org", "add")
                      }
                    }
                  },
                  [_vm._v("組織")]
                )
              ],
              1
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("span", [_vm._v("最近1週名單統計")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("span", [_vm._v("已覆核警示名單")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }